import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import { Modal } from "reactstrap"
import { X } from "react-feather"

import useTranslation from "../translations/translate"
import LanguageContext from "./LanguageContext"

import FlagEN from "../components/assets/media/flags/1x1/gb.svg"
import FlagDE from "../components/assets/media/flags/1x1/de.svg"
import FlagES from "../components/assets/media/flags/1x1/es.svg"
import FlagFR from "../components/assets/media/flags/1x1/fr.svg"
import FlagIT from "../components/assets/media/flags/1x1/it.svg"
import FlagHU from "../components/assets/media/flags/1x1/hu.svg"

const flags = {
  en: FlagEN,
  de: FlagDE,
  es: FlagES,
  fr: FlagFR,
  it: FlagIT,
  hu: FlagHU,
}

const LanguageSelector = () => {
  const { t } = useTranslation()
  const activeLang = useContext(LanguageContext)
  const [isVisible, setVisible] = useState(false)

  return (
    <>
      <Modal
        isOpen={isVisible}
        toggle={() => setVisible(!isVisible)}
        className="modal-dialog-centered modal-sm"
      >
        <div className="modal-body py-5 px-7 d-flex flex-column">
          <div className="mb-5 d-flex justify-content-between">
            <h5 className="modal-title" id="langModalTitle">
              Select language
            </h5>
            <button
              type="button"
              className="p-0 bg-transparent border-0"
              aria-label="Close"
              onClick={() => setVisible(!isVisible)}
            >
              <X />
            </button>
          </div>

          <ul className="p-0 lang-list list-unstyled list-style-none">
            <li className="mb-3">
              <img
                className="rounded-circle me-3"
                src={FlagEN}
                alt="language"
                width="32"
              />
              <Link
                to="/"
                className="h-fw-bold text-decoration-none"
                onClick={() => setVisible(false)}
              >
                English
              </Link>
            </li>

            <li className="mb-3">
              <img
                className="rounded-circle me-3"
                src={FlagDE}
                alt="language"
                width="32"
              />
              <Link
                to="/de/"
                className="h-fw-bold text-decoration-none"
                onClick={() => setVisible(false)}
              >
                Deutsch
              </Link>
            </li>

            <li className="mb-3">
              <img
                className="rounded-circle me-3"
                src={FlagES}
                alt="language"
                width="32"
              />
              <Link
                to="/es/"
                className="h-fw-bold text-decoration-none"
                onClick={() => setVisible(false)}
              >
                Español
              </Link>
            </li>

            <li className="mb-3">
              <img
                className="rounded-circle me-3"
                src={FlagFR}
                alt="language"
                width="32"
              />
              <Link
                to="/fr/"
                className="h-fw-bold text-decoration-none"
                onClick={() => setVisible(false)}
              >
                Français
              </Link>
            </li>

            <li className="mb-3">
              <img
                className="rounded-circle me-3"
                src={FlagIT}
                alt="language"
                width="32"
              />
              <Link
                to="/it/"
                className="h-fw-bold text-decoration-none"
                onClick={() => setVisible(false)}
              >
                Italiano
              </Link>
            </li>

            <li className="mb-0">
              <img
                className="rounded-circle me-3"
                src={FlagHU}
                alt="language"
                width="32"
              />
              <Link
                to="/hu/"
                className="h-fw-bold text-decoration-none"
                onClick={() => setVisible(false)}
              >
                Magyar
              </Link>
            </li>
          </ul>
        </div>
      </Modal>

      <div className="bg-dark">
        <div className="container text-white py-3 d-flex justify-content-between align-items-center flex-row">
          <p className="m-0">{t("common.chooseLanguage")}</p>

          <button
            type="button"
            className="bg-transparent border-0 p-0"
            onClick={() => setVisible(!isVisible)}
          >
            <img
              src={flags[activeLang]}
              width="32"
              className="rounded-circle"
              alt="Language flag"
            />
          </button>
        </div>
      </div>
    </>
  )
}

export default LanguageSelector
