import React, { useContext, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import useTranslation from "../translations/translate"
import LanguageContext from "./LanguageContext"

const FUNCTION_URL = "/api/newsletter/subscribe"

const Newsletter = () => {
  const activeLang = useContext(LanguageContext)
  const { t } = useTranslation()

  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [hasSubscribed, setHasSubscribed] = useState(false)
  const [hasError, setHasError] = useState(false)

  const onSubmit = async e => {
    e.preventDefault()

    if (isLoading) {
      return
    }

    setLoading(true)

    fetch(FUNCTION_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        language: activeLang,
      }),
    })
      .then(res => {
        setHasSubscribed(res.ok)
        setHasError(!res.ok)
        if (res.ok && !!window.fathom) {
          window.fathom.trackGoal("VISTGODV", 0)
        }
      })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return !hasSubscribed ? (
    <div className="text-center text-md-start">
      <p className="h1 mb-5">{t("component.newsletter.signUp")}</p>
      {hasError ? (
        <div className="alert bg-danger text-white shadow py-1" role="alert">
          {t("component.newsletter.error")}
        </div>
      ) : (
        <></>
      )}
      <form onSubmit={onSubmit} className="mb-2">
        <div className="d-flex">
          <div className="flex-grow-1 me-1">
            <input
              type="email"
              className="form-control m-0"
              name="email"
              aria-label={t("component.newsletter.enterEmail")}
              placeholder={t("component.newsletter.enterEmail")}
              value={data.email}
              onChange={e => setData({ ...data, email: e.target.value })}
              disabled={isLoading}
              required
            />
          </div>

          {isLoading ? (
            <button
              className="btn btn-light d-flex align-items-center"
              type="button"
              disabled
            >
              {t("common.loading")}
              <span
                className="spinner-grow spinner-grow-sm ms-2"
                role="status"
                aria-hidden="true"
              />
            </button>
          ) : (
            <button
              className="btn btn-light"
              type="submit"
              disabled={isLoading || (!!data.email && !data.captchaValue)}
            >
              {t("component.newsletter.subscribe")}
            </button>
          )}
        </div>

        {data.email && (
          <div className="mt-2">
            <ReCAPTCHA
              sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
              onChange={v => setData({ ...data, captchaValue: v })}
            />
          </div>
        )}
      </form>
      <p className="m-0">{t("component.newsletter.privacy")}</p>
    </div>
  ) : (
    <div className="text-center text-md-start">
      <p className="h1 mb-5">{t("component.newsletter.thankYou")}</p>
      <div className="row align-items-center mb-5">
        <div className="col-2">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2"
          >
            <circle
              className="path circle"
              fill="none"
              stroke="#ffffff"
              strokeWidth="6"
              strokeMiterlimit="10"
              cx="65.1"
              cy="65.1"
              r="62.1"
            />
            <polyline
              className="path check"
              fill="none"
              stroke="#ffffff"
              strokeWidth="6"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="100.2,40.2 51.5,88.8 29.8,67.5 "
            />
          </svg>
        </div>

        <div className="col-10">
          <p className="m-0">{t("component.newsletter.success")}</p>
        </div>
      </div>
    </div>
  )
}

export default Newsletter
