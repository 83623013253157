import React from "react"
import Helmet from "react-helmet"

import LanguageAlert from "./src/components/LanguageAlert"
import LanguageContext from "./src/components/LanguageContext"
import Footer from "./src/components/Footer"
import AOS from "./src/components/AOS"

export const wrapPageElement = ({ element, props }) => (
  <LanguageContext.Provider value={props.pageContext.language}>
    <AOS>
      <Helmet>
        <script
          src="https://octopus.evobend.com/script.js"
          data-site="DYGICVCJ"
          defer
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <LanguageAlert />
      {element}
      <Footer />
    </AOS>
  </LanguageContext.Provider>
)
