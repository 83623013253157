import { useEffect, useMemo } from "react"
import AOS from "aos"

const AOSWrapper = ({ children }) => {
  useMemo(() => {
    AOS.refresh()
  }, [])

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out-quad",
      once: true,
    })
  }, [])

  return children
}

export default AOSWrapper
