import React, { useContext } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import useTranslation from "../translations/translate"

import Banner from "./Banner"
import LanguageSelector from "./LanguageSelector"
import Newsletter from "./Newsletter"
import LanguageContext from "./LanguageContext"

const Footer = () => {
  const activeLang = useContext(LanguageContext)
  const { t, getLocalizedPath } = useTranslation()
  const { siteMetadata } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          facebookUrl
          instagramUrl
          youTubeUrl
          linkedInUrl
          whatsAppUrl
        }
      }
    }
  `).site

  return (
    <>
      <Banner />
      <footer className="ev-footer py-10">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3 mb-5 mb-md-0 text-center text-md-start">
              <p className="font-weight-normal h4 mb-4">
                <span className="fw-bold">EVOBEND</span> GmbH
              </p>
              <p>
                Am Schmidinger Weiher 3 <br />
                83404 Ainring <br />
                {t("common.country.germany")}
              </p>

              <p>
                <a
                  href={siteMetadata.whatsAppUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                >
                  WhatsApp
                </a>
              </p>

              <p className="m-0">
                <a href="mailto:info@evobend.com" className="text-white">
                  info@evobend.com
                </a>
                <br />
                <a href="tel:004986546824440" className="text-white">
                  +49 8654 6824 440
                </a>
                <br />
              </p>
            </div>

            <div className="col-6 col-md-3 col-lg-2 text-end text-md-start">
              <p className="text-uppercase small">
                {t("component.footer.pages")}
              </p>

              <ul className="list-unstyled">
                <li>
                  <Link to={getLocalizedPath("explore")} className="text-white">
                    {t("page.explore.title")}
                  </Link>
                </li>

                <li>
                  <Link
                    to={getLocalizedPath("about-us")}
                    className="text-white"
                  >
                    {t("page.aboutUs.title")}
                  </Link>
                </li>

                <li>
                  <Link
                    to={getLocalizedPath("trade-fairs")}
                    className="text-white"
                  >
                    {t("page.tradeFairs.title")}
                  </Link>
                </li>

                <li>
                  <Link to={getLocalizedPath("contact")} className="text-white">
                    {t("page.contact.title")}
                  </Link>
                </li>

                {activeLang === "de" && (
                  <li>
                    <Link
                      to={getLocalizedPath("career")}
                      className="text-white text-decoration-none"
                    >
                      <div className="d-flex flex-row-reverse flex-md-row justify-content-start align-items-center mb-0">
                        <div className="text-decoration-underline">
                          Karriere
                        </div>
                        <div
                          className="bg-danger rounded-1 me-2 me-md-0 ms-md-2 text-uppercase fw-bolder shadow"
                          style={{ fontSize: "0.8rem", padding: "0px 10px" }}
                        >
                          Jobs
                        </div>
                      </div>
                    </Link>
                  </li>
                )}

                <li>
                  <Link to={getLocalizedPath("privacy")} className="text-white">
                    {t("page.privacy.title")}
                  </Link>
                </li>

                <li>
                  <Link to={getLocalizedPath("imprint")} className="text-white">
                    {t("page.imprint.title")}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-6 col-md-3 col-lg-2">
              <p className="text-uppercase small">{t("common.followUs")}</p>

              <ul className="list-unstyled">
                <li>
                  <a
                    href={siteMetadata.facebookUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href={siteMetadata.youTubeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    YouTube
                  </a>
                </li>
                <li>
                  <a
                    href={siteMetadata.instagramUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href={siteMetadata.linkedInUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-12 col-lg-5">
              <Newsletter />
            </div>
          </div>
        </div>
      </footer>
      <LanguageSelector />
    </>
  )
}

export default Footer
