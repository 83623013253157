import React from "react"
import { Link } from "gatsby"

import useTranslation from "../translations/translate"

const Banner = ({ className }) => {
  const { t } = useTranslation()

  return (
    <div
      className={`py-9 bg-light px-md-5 ${
        !!className ? className : "border-top"
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mb-5 mb-md-0 my-auto text-center">
            <p className="h2 m-0 fw-bold">{t("component.banner.message")}</p>
          </div>

          <div className="col-12 col-md-6 col-lg-4 my-auto text-center">
            {t("component.banner.href").startsWith("/") ? (
              <Link
                to={t("component.banner.href")}
                role="button"
                className="btn btn-danger shadow"
              >
                {t("component.banner.callForAction")}
              </Link>
            ) : (
              <a
                href={t("component.banner.href")}
                target="_blank"
                rel="noopener noreferrer"
                role="button"
                className="btn btn-danger shadow"
              >
                {t("component.banner.callForAction")}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
