import React, { useContext } from "react"
import snarkdown from "snarkdown"

import LanguageContext from "../components/LanguageContext"

import config from "./config.json"
import de from "./de.json"
import en from "./en.json"
import it from "./it.json"
import fr from "./fr.json"
import es from "./es.json"
import hu from "./hu.json"

const { defaultLanguage, pathTranslations } = config

const resources = {
  en,
  de,
  it,
  fr,
  es,
  hu,
}

const useTranslation = () => {
  const activeLang = useContext(LanguageContext)

  const getTranslation = (key, markdown = false, lang = activeLang) => {
    if (!resources[lang]) {
      return key
    }

    let current = resources[lang]
    const objKeys = key.split(".")

    for (let i = 0; i < objKeys.length; i++) {
      if (current[objKeys[i]]) {
        current = current[objKeys[i]]
      } else {
        return key
      }
    }

    if (markdown) {
      return <span dangerouslySetInnerHTML={{ __html: snarkdown(current) }} />
    }

    return current
  }

  const getPath = (lang, pageName) => {
    if (pageName === "/" || pageName === "" || pageName === "index") {
      return lang === defaultLanguage ? "/" : `/${lang}/`
    }

    const pagePath = `/${pageName}/`

    if (lang === defaultLanguage) {
      return pagePath
    }

    if (!!pathTranslations[pageName] && !!pathTranslations[pageName][lang]) {
      return `/${lang}/${pathTranslations[pageName][lang]}/`
    }

    return `/${lang}/${pageName}/`
  }

  const getLocalizedPath = pageName => {
    return getPath(activeLang, pageName)
  }

  return { t: getTranslation, getLocalizedPath, getPath }
}

export default useTranslation
