module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EVOBEND","short_name":"EVOBEND","start_url":"/","background_color":"#f8f9fa","theme_color":"#2d3e48","display":"minimal-ui","icon":"src/components/assets/favicon.png","icon_options":{"purpose":"maskable"},"lang":"en","localize":[{"start_url":"/de/","lang":"de"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e339766e35a336186cf2af1575ce47ad"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
