import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import { X } from "react-feather"

import useTranslation from "../translations/translate"
import LanguageContext from "./LanguageContext"

import config from "../translations/config.json"

import FlagEN from "../components/assets/media/flags/1x1/gb.svg"
import FlagDE from "../components/assets/media/flags/1x1/de.svg"
import FlagES from "../components/assets/media/flags/1x1/es.svg"
import FlagFR from "../components/assets/media/flags/1x1/fr.svg"
import FlagIT from "../components/assets/media/flags/1x1/it.svg"
import FlagHU from "../components/assets/media/flags/1x1/hu.svg"

const flags = {
  en: FlagEN,
  de: FlagDE,
  es: FlagES,
  fr: FlagFR,
  it: FlagIT,
  hu: FlagHU,
}

const LanguageAlert = () => {
  const { t, getPath } = useTranslation()
  const activeLang = useContext(LanguageContext)
  const [suggestedLang, setSuggestedLang] = useState("en")
  const [isVisible, setVisible] = useState(false)

  const dismiss = () => {
    setVisible(false)
  }

  useEffect(() => {
    const navigatorLang = navigator.language.toLowerCase().substring(0, 2)
    setSuggestedLang(
      config.enabledLanguages.hasOwnProperty(navigatorLang)
        ? navigatorLang
        : suggestedLang
    )
    setVisible(activeLang !== suggestedLang)
  }, [activeLang, suggestedLang, setSuggestedLang])

  return (
    isVisible && (
      <div className="bg-dark position-sticky top-0 z-100 shadow-lg">
        <div className="container d-flex align-items-center justify-content-between py-3">
          <p className="small text-white mb-0">
            {t("common.chooseLanguage", false, suggestedLang)}
          </p>
          <Link
            to={getPath(suggestedLang, "index")}
            className="bg-white rounded py-2 px-4 text-decoration-none ms-auto me-3"
          >
            <div className="d-flex flex-row align-items-center">
              <img
                src={flags[suggestedLang]}
                width="32"
                className="rounded-circle me-1"
                alt="Language flag"
              />
              <span>{config.enabledLanguages[suggestedLang].name}</span>
            </div>
          </Link>

          <button
            onClick={dismiss}
            type="button"
            className="p-0 bg-transparent border-0"
            aria-label={t("common.close")}
          >
            <X className="text-white" />
          </button>
        </div>
      </div>
    )
  )
}

export default LanguageAlert
